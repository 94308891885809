import React from 'react';
import { Navbar } from 'react-bootstrap';
import HeaderImage from '../../images/header.png';

import './Header.css';

const Header = () => (
  <Navbar>
    <Navbar.Brand href="/">
      <img
        alt=""
        src={HeaderImage}
        width="200"
        height="100"
        className="d-inline-block align-top"
      />
    </Navbar.Brand>
  </Navbar>
);

export default Header;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'; // to take image data and render it
import {
  Button, Container, Form, Toast,
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/store/Header';
import Logo from '../../images/svg/LOGO.svg';

import './board.css';
import GripSquare from '../../components/store/squares';

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

function Grip({ data }) {
  const { griptape } = data;

  const [validated, setValidated] = React.useState(false);
  const [name, setName] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const squares = data.allSquare.nodes;

  const [squaresQuantities, setSquareQuantities] = useState([...Array(5)].fill(0));

  const updateSquareQuantity = (idx, qty) => {
    const arr = [...squaresQuantities];
    arr[idx] = parseInt(qty, 10);
    setSquareQuantities(arr);
  };

  const getSquareInfo = () => {
    const squaresInfo = {};
    if (griptape.type === 'multi') {
      squares.forEach((square, idx) => {
        squaresInfo[`square${square.name.replace(/\s/g, '')}`] = squaresQuantities[idx];
      });
    }
    return squaresInfo;
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    const validity = form.checkValidity();

    if (!validity) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (validity) {
      e.preventDefault();
      const generalInfo = {
        'form-name': e.target.getAttribute('name'),
        name,
        contact,
        griptape: `${griptape.name}`,
      };

      const join = Object.assign(generalInfo, getSquareInfo());

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode(join),
      })
        .then(() => {
          setMessage('Thank you! We will contact you soon.');
          setShow(true);
        })
        .catch(() => {
          setMessage('Sorry, we did not receive your email. Please try again later!');
          setShow(true);
        });
    }
  };

  return (
    <Layout>
      <SEO title="Filinto Board" />
      <Header />
      <Container>
        <div className="grip-item">
          <Img
            style={{ height: '500px', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
            fluid={griptape.img.childImageSharp.fluid}
            alt={griptape.name}
          />
          <span className="board-name">
            {griptape.name}
          </span>
          <span className="board-brand"> 1900 </span>

          {griptape.type === 'multi' && (
            <span className="board-brand">
              A full board needs 4 squares
            </span>
          )}
          {griptape.type === 'multi'
            ? (
              <span className="price">
                {squaresQuantities.reduce((prev, val, idx) => (val * squares[idx].price + prev), 0)}
                {' '}
                biscas
              </span>
            )
            : (
              <span className="price">
                {griptape.price}
                {' '}
                biscas
              </span>
            )}

          {griptape.type === 'multi' && squares.map((square, idx) => (
            <GripSquare key={idx} square={square} idx={idx} update={updateSquareQuantity} />
          ))}
          <Form
            id="contact-form"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="griptapes"
            method="post"
          >
            <input type="hidden" name="form-name" value="griptapes" />
            <input type="hidden" name="griptape" value={`${griptape.name}`} />
            {squares.map((square, idx) => (
              <input type="hidden" name={`square${square.name.replace(/\s/g, '')}`} value={squaresQuantities[idx]} />
            ))}
            <span>
              Send us a message to buy one!
            </span>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control name="name" type="text" required placeholder="Name" value={name} onChange={(evt) => setName(evt.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Contact</Form.Label>
              <Form.Control name="contact" type="text" required placeholder="Instagram, email or phone number" minLength="9" value={contact} onChange={(evt) => setContact(evt.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide a valid way of contacting you.
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="send-button" variant="success" type="submit">
              Send
            </Button>
          </Form>
        </div>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            top: '3em',
            right: '2em',
          }}
        >
          <Toast.Header>
            <img
              src={Logo}
              className="rounded mr-2"
              alt=""
              width="30"
              height="30"
            />
            <strong className="mr-auto">1900 - Store</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>

      </Container>
    </Layout>
  );
}

export default Grip;

export const query = graphql`
  query($id: String!) {
    griptape (id: { eq: $id }) {
      name
      price
      url
      type
      img {
        childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allSquare {
      nodes {
        name
        price
        url
        img {
          childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

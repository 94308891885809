import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/store/Header';
import Products from '../components/store/product';

const IndexPage = () => (
  <Layout>
    <SEO title="Store" />
    <Header />
    <Products />
  </Layout>
);

export default IndexPage;

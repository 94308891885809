/* eslint-disable react/prop-types */
import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'; // to take image data and render it
import {
  Button, Container, Form, Toast,
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/store/Header';
import Logo from '../../images/svg/LOGO.svg';
import GripSquare from '../../components/store/squares';

import './board.css';

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

function Board({ data }) {
  const { board } = data;
  const squares = data.allSquare.nodes;
  const grips = data.allGriptape.nodes;

  const [squaresQuantities, setSquareQuantities] = React.useState([...Array(5)].fill(0));

  const updateSquareQuantity = (idx, qty) => {
    const arr = [...squaresQuantities];
    arr[idx] = parseInt(qty, 10);
    setSquareQuantities(arr);
  };
  const [validated, setValidated] = React.useState(false);
  const [name, setName] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [selectedSize, setSelectedSize] = React.useState('');
  const [griptape, setGriptape] = React.useState('no');
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const getSquareInfo = () => {
    const squaresInfo = {};
    if (griptape.includes('Tetris')) {
      squares.forEach((square, idx) => {
        squaresInfo[`square${square.name.replace(/\s/g, '')}`] = `${squaresQuantities[idx]}`;
      });
    }
    return squaresInfo;
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    const validity = form.checkValidity();
    if (!validity) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (validity) {
      e.preventDefault();
      const generalInfo = {
        'form-name': e.target.getAttribute('name'),
        name,
        contact,
        griptape,
        board: `${board.name}-${board.url}`,
        size: selectedSize,
      };

      const join = Object.assign(generalInfo, getSquareInfo());

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode(join),
      })
        .then(() => {
          setMessage('Thank you! We will contact you soon.');
          setShow(true);
        })
        .catch(() => {
          setMessage('Sorry, we did not receive your email. Please try again later!');
          setShow(true);
        });
    }
  };

  const getGripPrice = () => {
    if (griptape.includes('Tetris')) return squaresQuantities.reduce((prev, val, idx) => (val * squares[idx].price + prev), 0);
    if (griptape === 'no') return 0;
    if (griptape === 'free') return 0;
    return grips.find((sq) => sq.name === griptape).price;
  };

  return (
    <Layout>
      <SEO title={`${board.name}`} />
      <Header />
      <Container>
        <div className="board-item">
          <Img
            style={{ height: '500px', width: '10em' }}
            imgStyle={{ objectFit: 'contain' }}
            fluid={board.img.childImageSharp.fluid}
            alt={board.name}
            className="hover-zoom"
          />
          <span className="board-name">
            {board.name}
          </span>
          <span className="board-brand">
            {board.concave}
            {' '}
            concave
          </span>
          <span className="board-brand"> 1900 </span>
          <span className="price">
            {board.price}
            {' '}
            biscas
          </span>
          {board.status !== 'soldout' && (
            <Form
              id="contact-form"
              onSubmit={handleSubmit}
              noValidate
              validated={validated}
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              name="boards"
              method="post"
            >
              <input type="hidden" name="form-name" value="boards" />
              <input type="hidden" name="board" value={`${board.name}-${board.url}`} />
              {/*               {squares.map((square, idx) => (
                <input type="hidden" name={`square${square.name.replace(/\s/g, '')}`}
                 value={squaresQuantities[idx]} />
              ))} */}
              <Form.Label>Select size:</Form.Label>
              <Form.Control name="size" as="select" custom value={selectedSize} onChange={(evt) => setSelectedSize(evt.target.value)}>
                {board.sizes && board.sizes.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Form.Control>
              <Form.Label>Add griptape:</Form.Label>
              <Form.Control name="griptape" as="select" custom value={griptape} onChange={(evt) => setGriptape(evt.target.value)}>
                <option value="free"> Drip Basic (FREE) </option>
                <option value="no"> None </option>
                {/*                 {grips.length && grips.map((grip) => (
                  <option key={grip.id} value={grip.name}>
                    {' '}
                    {grip.name}
                    {' '}
                    {grip.type !== 'multi' ? `(+${grip.price} biscas)` : ''}
                    {' '}
                  </option>
                ))} */}
              </Form.Control>
              {/*               {griptape.includes('Tetris') && squares.map((square, idx) => (
                <GripSquare
                  key={square.meta.id}
                  square={square}
                  idx={idx}
                  update={updateSquareQuantity}
                />
              ))} */}
              <span>
                Send us a message to buy one!
              </span>
              <Form.Group controlId="validationCustom03">
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" type="text" required placeholder="Name" value={name} onChange={(evt) => setName(evt.target.value)} />
                <Form.Control.Feedback type="invalid">
                  Please provide your name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom05">
                <Form.Label>Contact</Form.Label>
                <Form.Control name="contact" type="text" required placeholder="Instagram, email or phone number" value={contact} onChange={(evt) => setContact(evt.target.value)} />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid way of contacting you.
                </Form.Control.Feedback>
              </Form.Group>
              <span>
                Total price:
                {' '}
                {board.price + getGripPrice()}
                {' '}
                biscas
              </span>
              <Button className="send-button" variant="success" type="submit">
                Send
              </Button>
            </Form>
          )}
        </div>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            top: '3em',
            right: '2em',
          }}
        >
          <Toast.Header>
            <img
              src={Logo}
              className="rounded mr-2"
              alt=""
              width="30"
              height="30"
            />
            <strong className="mr-auto">1900 - Store</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>

      </Container>
    </Layout>
  );
}

export default Board;

export const query = graphql`
  query($id: String!) {
    board (id: { eq: $id }) {
      name
      price
      url
      status
      concave
      sizes
      img {
        childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
          }
        }
      }
    }
    allGriptape {
      nodes{
        name
        price
        url
        type
        meta { id }
        img {
          childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allSquare {
      nodes {
        name
        price
        url
        meta { id }
        img {
          childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

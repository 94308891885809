// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boards-board-url-js": () => import("./../../../src/pages/boards/{Board.url}.js" /* webpackChunkName: "component---src-pages-boards-board-url-js" */),
  "component---src-pages-griptapes-griptape-url-js": () => import("./../../../src/pages/griptapes/{Griptape.url}.js" /* webpackChunkName: "component---src-pages-griptapes-griptape-url-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-tees-tee-url-js": () => import("./../../../src/pages/tees/{Tee.url}.js" /* webpackChunkName: "component---src-pages-tees-tee-url-js" */)
}


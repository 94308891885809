/* eslint-disable react/prop-types */
import React from 'react';
import Img from 'gatsby-image'; // to take image data and render it

const gripValues = ([...Array(11).keys()]);

const GripSquare = ({ square, idx, update }) => (
  <div className="grip-wrapper">
    <div className="grip-squares">
      <Img
        style={{ height: '80px', width: '80px' }}
        imgStyle={{ objectFit: 'contain' }}
        fluid={square.img.childImageSharp.fluid}
        alt={square.name}
      />
      <span>
        {square.name}
      </span>
      <select onChange={(e) => update(idx, e.target.value)}>
        {gripValues.map((e) => (
          <option key={e} value={e}>{e}</option>
        ))}
      </select>

    </div>
    <span className="square-price">
      Price per square:
      {' '}
      {square.price}
      biscas
      {' '}
    </span>
  </div>
);
export default GripSquare;

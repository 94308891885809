import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby'; // to query for image data
import Img from 'gatsby-image'; // to take image data and render it
import { Container } from 'react-bootstrap';
import './product.css';

const Products = () => {
  const data = useStaticQuery(graphql`
    query {
      boards: allBoard {
        nodes {
          name
          price
          url
          status
          sizes
          nameSlug: gatsbyPath(filePath: "/boards/{Board.url}")
          img {
            childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      griptapes: allGriptape {
        nodes {
          name
          price
          url
          status
          nameSlug: gatsbyPath(filePath: "/griptapes/{Griptape.url}")
          img {
            childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      tees: allTee {
        nodes {
          name
          price
          url
          status
          nameSlug: gatsbyPath(filePath: "/tees/{Tee.url}")
          img {
            childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Container className="products-container">
      <div>
        <div className="production-section">
          <span className="product-selection">
            Boards
          </span>
          <div className="products boards">
            {data.boards.nodes.map((board) => (
              <Link className={`product ${board.status} hover-zoom`} to={board.nameSlug}>
                <Img
                  style={{ height: '300px', width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                  fluid={board.img.childImageSharp.fluid}
                  alt={board.name}
                />
                <span className="board-name">
                  {board.name}
                </span>
                {board.status !== 'soldout' && (
                <span className="price">
                  {board.price}
                  {' '}
                  biscas
                </span>
                )}
              </Link>
            ))}
          </div>
        </div>
        <div className="production-section">
          <div className="horizontal-section-break">
            <span className="product-selection">
              Tees
            </span>
            <div className="products tees">
              {data.tees.nodes.map((tee) => (
                <Link className="product" to={tee.nameSlug}>
                  <Img
                    style={{ height: '300px', width: '100%' }}
                    imgStyle={{ objectFit: 'contain' }}
                    fluid={tee.img.childImageSharp.fluid}
                    alt={tee.name}
                  />
                  <span className="board-name">
                    {tee.name}
                  </span>
                  {tee.status !== 'soldout' && (
                  <span className="price">
                    {tee.price === 0 ? '6+ biscas' : `${tee.price} biscas`}
                  </span>
                  )}
                </Link>
              ))}
            </div>
          </div>

          <div className="horizontal-section-break">
            <span className="product-selection">
              Griptapes
            </span>
            <div className="products griptapes">
              {data.griptapes.nodes.map((grip) => (
                <Link className={`product ${grip.status}`} to={grip.nameSlug}>
                  <Img
                    style={{ height: '300px', width: '100%' }}
                    imgStyle={{ objectFit: 'contain' }}
                    fluid={grip.img.childImageSharp.fluid}
                    alt={grip.name}
                  />
                  <span className="board-name">
                    {grip.name}
                  </span>
                  {grip.status !== 'soldout' && (
                  <span className="price">
                    {grip.price === 0 ? '6+ biscas' : `${grip.price} biscas`}
                  </span>
                  )}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Products;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'; // to take image data and render it
import {
  Button, Container, Form, Toast, Alert
} from 'react-bootstrap';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/store/Header';
import Logo from '../../images/svg/LOGO.svg';

import './tee.css';

const encode = (data) => Object.keys(data)
  .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
  .join('&');

function Tee({ data }) {
  const { tee } = data;
  const teeName = tee.name;

  const availableSizes = tee.sizes.split(',');
  const [size, setSize] = React.useState('M');
  const [validated, setValidated] = React.useState(false);
  const [name, setName] = React.useState('');
  const [contact, setContact] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    const validity = form.checkValidity();

    if (!validity) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (validity) {
      e.preventDefault();
      const generalInfo = {
        'form-name': e.target.getAttribute('name'),
        name,
        contact,
        tee: teeName,
        size,
      };

      console.log(generalInfo);

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode(generalInfo),
      })
        .then(() => {
          setMessage('Thank you! We will contact you soon.');
          setShow(true);
        })
        .catch(() => {
          setMessage('Sorry, we did not receive your email. Please try again later!');
          setShow(true);
        });
    }
  };

  return (
    <Layout>
      <SEO title="T-shirt 1900" />
      <Header />
      <Container>
        <div className="tee-item">
          <Img
            style={{ height: '500px', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
            fluid={tee.img.childImageSharp.fluid}
            alt={tee.name}
          />
          <span className="board-name">
            {tee.name}
          </span>
          <span className="board-brand"> 1900 </span>

          <span className="price">
            {tee.price}
            {' '}
            biscas
          </span>

          <Form
            id="contact-form"
            onSubmit={handleSubmit}
            noValidate
            validated={validated}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="tshirt-form"
            method="post"
          >
            <input type="hidden" name="form-name" value="tshirt-form" />
            <input type="hidden" name="tee" value={teeName} />
            <input type="hidden" name="size" value={size} />
            <Form.Label>Select size:</Form.Label>
            <Form.Control className="small-select" name="size" as="select" custom value={size} onChange={(evt) => setSize(evt.target.value)}>
              {tee.sizes && availableSizes.map((availableSize) => (
                <option key={availableSize} value={availableSize}>
                  {availableSize}
                </option>
              ))}
            </Form.Control>
            <Alert className="inventory-alert" variant="danger">
              Due to the COVID-19 pandemic, some sizes are not available. If your size is not on the list, please contact us through Facebook or Instagram.
            </Alert>
            <span>
              Send us a message to buy one!
            </span>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control name="name" type="text" required placeholder="Name" value={name} onChange={(evt) => setName(evt.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Contact</Form.Label>
              <Form.Control name="contact" type="text" required placeholder="Instagram, email or phone number" minLength="9" value={contact} onChange={(evt) => setContact(evt.target.value)} />
              <Form.Control.Feedback type="invalid">
                Please provide a valid way of contacting you.
              </Form.Control.Feedback>
            </Form.Group>
            <Button className="send-button" variant="success" type="submit">
              Send
            </Button>
          </Form>
        </div>
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            top: '3em',
            right: '2em',
          }}
        >
          <Toast.Header>
            <img
              src={Logo}
              className="rounded mr-2"
              alt=""
              width="30"
              height="30"
            />
            <strong className="mr-auto">1900 - Store</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>

      </Container>
    </Layout>
  );
}

export default Tee;


export const query = graphql`
  query($id: String!) {
    tee (id: { eq: $id }) {
      name
      price
      url
      status
      sizes
      img {
        childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/store/Header';
import Products from '../components/store/product';
import { AiOutlineInstagram, AiOutlineFacebook } from 'react-icons/ai';

import './index.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Store" />
    <Header />
    <Products />
    <footer>
      <div>
        <a href="https://www.instagram.com/1900label/" style={{ color: "black" }}>
          <AiOutlineInstagram size={30} />
        </a>
        <a href="https://www.facebook.com/1900label" style={{ color: "black" }}>
          <AiOutlineFacebook size={30} />
        </a>
      </div>
    </footer>
  </Layout>
);

export default IndexPage;

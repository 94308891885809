import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import './layout.css';

const Layout = ({ id, children }) => (
  <>
    <Container id={id}>
      {children}
    </Container>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Layout.defaultProps = {
  id: 'main-page-container',
};

export default Layout;
